import * as React from 'react';

import MainLayout from '../layouts/MainLayout';
import { Seo, Box, Text, Link } from '../components';

const PrivacyPolicy = () => {
  return (
    <MainLayout>
      <Seo title="SAPP Privacy Policy" />
      <Box px={[0, 100]} py={50} width={[1, 1, 960]} m="0 auto">
        <Text variant="h2" mb={3}>
          Section 1: Introduction
        </Text>

        <Text mb={3}>
          1.1 We are committed to safeguarding the privacy of our website
          visitors, service users, individual customers, and customer personnel.
        </Text>
        <Text mb={3}>
          1.2 We use cookies on our website. Insofar as those cookies are not
          strictly necessary for the provision of our website and services, we
          will ask you to consent to our use of cookies when you first visit our
          website.
        </Text>
        <Text mb={4}>
          1.3 In this policy, “we”, “us” and “our” refer to “SAPP”. For more
          information about us, see Section 14.
        </Text>
        <Text variant="h2" mb={3}>
          Section 2: Credit
        </Text>
        <Text mb={4}>
          2.1 This document was created using a template from Docular:{' '}
          <Link to="https://seqlegal.com/free-legal-documents/privacy-policy">
            https://seqlegal.com/free-legal-documents/privacy-policy
          </Link>
          .
        </Text>

        <Text variant="h2" mb={3}>
          Section 3: The personal data that we collect
        </Text>
        <Text mb={3}>
          3.1 In this Section 3 we have set out the general categories of
          personal data that we process and, in the case of personal data that
          we did not obtain directly from you, information about the source and
          specific categories of that data.
        </Text>
        <Text mb={3}>
          3.2 We may process data enabling us to get in touch with you (“contact
          data“). The contact data may include your name, email address,
          telephone number, postal address and/or social media account
          identifiers. The source of the contact data is you and/or your
          employer. If you log into our website using a social media account, we
          will obtain elements of the contact data from the relevant social
          media account provider.
        </Text>
        <Text mb={3}>
          3.3 We may process your website user account data (“account data“).
          The account data may include your account identifier, name, email
          address, business name, account creation and modification dates,
          website settings and marketing preferences. The primary source of the
          account data is you and/or your employer, although some elements of
          the account data may be generated by our website. If you log into our
          website using a social media account, we will obtain elements of the
          account data from the relevant social media account provider.
        </Text>
        <Text mb={3}>
          3.4 We may process information relating to transactions, including
          purchases of goods and/or services, that you enter into with us and/or
          through our website (“transaction data“). The transaction data may
          include your name, your contact details, your payment card details (or
          other payment details) and the transaction details. The source of the
          transaction data is you and/or our payment services provider.
        </Text>
        <Text mb={3}>
          3.5 We may process information contained in or relating to any
          communication that you send to us or that we send to you
          (“communication data“). The communication data may include the
          communication content and metadata associated with the communication.
          Our website will generate the metadata associated with communications
          made using the website contact forms.
        </Text>
        <Text mb={4}>
          3.6 We may process data about your use of our website and services
          (“usage data“). The usage data may include your IP address,
          geographical location, browser type and version, operating system,
          referral source, length of visit, page views and website navigation
          paths, as well as information about the timing, frequency, and pattern
          of your service use. The source of the usage data is our analytics
          tracking system.
        </Text>
        <Text variant="h2" mb={3}>
          Section 4: Purposes of processing and legal bases
        </Text>
        <Text mb={3}>
          4.1 In this Section 4, we have set out the purposes for which we may
          process personal data and the legal bases of the processing.
        </Text>
        <Text mb={3}>
          4.2 Operations – We may process your personal data for the purposes of
          operating our website, the processing and fulfilment of orders,
          providing our services, supplying our goods, generating invoices,
          bills and other payment-related documentation, and credit control. The
          legal basis for this processing is our legitimate interests, namely
          the proper administration of our website, services and business OR the
          performance of a contract between you and us and/or taking steps, at
          your request, to enter into such a contract.
        </Text>
        <Text mb={3}>
          4.3 Publications – We may process account data for the purposes of
          publishing such data on our website and elsewhere through our services
          in accordance with your express instructions. The legal basis for this
          processing is consent OR our legitimate interests, namely the
          publication of content in the ordinary course of our operations OR the
          performance of a contract between you and us and/or taking steps, at
          your request, to enter into such a contract.
        </Text>
        <Text mb={3}>
          4.4 Relationships and communications – We may process contact data,
          account data, transaction data and/or communication data for the
          purposes of managing our relationships, communicating with you
          (excluding communicating for the purposes of direct marketing) by
          email, SMS, post, fax and/or telephone, providing support services and
          complaint handling. The legal basis for this processing is our
          legitimate interests, namely communications with our website visitors,
          service users, individual customers and customer personnel, the
          maintenance of relationships, and the proper administration of our
          website, services, and business.
        </Text>
        <Text mb={3}>
          4.5 Direct marketing – We may process contact data, account data
          and/or transaction data for the purposes of creating, targeting and
          sending direct marketing communications by email, SMS, post and/or fax
          and making contact by telephone for marketing-related purposes. The
          legal basis for this processing is consent OR our legitimate
          interests, namely promoting our business and communicating marketing
          messages and offers to our website visitors and service users.
        </Text>
        <Text mb={3}>
          4.6 Research and analysis – We may process usage data and/or
          transaction data for the purposes of researching and analyzing the use
          of our website and services, as well as researching and analyzing
          other interactions with our business. The legal basis for this
          processing is consent OR our legitimate interests, namely monitoring,
          supporting, improving, and securing our website, services, and
          business generally.
        </Text>
        <Text mb={3}>
          4.7 Record keeping – We may process your personal data for the
          purposes of creating and maintaining our databases, back-up copies of
          our databases and our business records generally. The legal basis for
          this processing is our legitimate interests, namely ensuring that we
          have access to all the information we need to run our business
          properly and efficiently in accordance with this policy.
        </Text>
        <Text mb={3}>
          4.8 Security – We may process your personal data for the purposes of
          security and the prevention of fraud and other criminal activity. The
          legal basis of this processing is our legitimate interests, namely the
          protection of our website, services and business, and the protection
          of others.
        </Text>
        <Text mb={3}>
          4.9 Legal claims – We may process your personal data where necessary
          for the establishment, exercise or defense of legal claims, whether in
          court proceedings or in an administrative or out-of-court procedure.
          The legal basis for this processing is our legitimate interests,
          namely the protection and assertion of our legal rights, your legal
          rights, and the legal rights of others.
        </Text>
        <Text mb={4}>
          4.10 Legal compliance and vital interests – We may also process your
          personal data where such processing is necessary for compliance with a
          legal obligation to which we are subject or in order to protect your
          vital interests or the vital interests of another natural person.
        </Text>

        <Text variant="h2" mb={3}>
          Section 5: Providing your personal data to others
        </Text>
        <Text mb={3}>
          5.1 Your personal data held in our website database will be stored on
          the servers of our hosting services providers.
        </Text>
        <Text mb={3}>
          5.2 Financial transactions relating to our website and services are
          handled by our payment services providers. We will share transaction
          data with our payment services providers only to the extent necessary
          for the purposes of processing your payments, refunding such payments,
          and dealing with complaints and queries relating to such payments and
          refunds. You can find information about the payment services
          providers’ privacy policies and practices at [URLs].
        </Text>
        <Text mb={3}>
          5.3 In addition to the specific disclosures of personal data set out
          in this Section 5, we may disclose your personal data where such
          disclosure is necessary for compliance with a legal obligation to
          which we are subject, or in order to protect your vital interests or
          the vital interests of another natural person. We may also disclose
          your personal data where such disclosure is necessary for the
          establishment, exercise, or defense of legal claims, whether in court
          proceedings or in an administrative or out-of-court procedure.
        </Text>

        <Text variant="h2" mb={3}>
          Section 6: International transfers of your personal data
        </Text>
        <Text mb={3}>
          6.1 In this Section 6, we provide information about the circumstances
          in which your personal data may be transferred to countries outside
          the United Kingdom and the European Economic Area (EEA).
        </Text>
        <Text mb={4}>
          6.2 You acknowledge that personal data that you submit for publication
          through our website or services may be available, via the internet,
          around the world. We cannot prevent the use (or misuse) of such
          personal data by others.
        </Text>

        <Text variant="h2" mb={3}>
          Section 7: Retaining and deleting personal data
        </Text>
        <Text mb={3}>
          7.1 This Section 7 sets out our data retention policies and
          procedures, which are designed to help ensure that we comply with our
          legal obligations in relation to the retention and deletion of
          personal data.
        </Text>
        <Text mb={3}>
          7.2 Personal data that we process for any purpose or purposes shall
          not be kept for longer than is necessary for that purpose or those
          purposes.
        </Text>
        <Text mb={4}>
          7.3 Notwithstanding the other provisions of this Section 7, we may
          retain your personal data where such retention is necessary for
          compliance with a legal obligation to which we are subject, or in
          order to protect your vital interests or the vital interests of
          another natural person.
        </Text>

        <Text variant="h2" mb={3}>
          Section 8: Your rights
        </Text>
        <Text mb={3}>
          8.1 In this Section 8, we have listed the rights that you have under
          data protection law.
        </Text>
        <Text mb={3}>
          8.2 Your principal rights under data protection law are:
          <br /> (a) the right to access – you can ask for copies of your
          personal data;
          <br /> (b) the right to rectification – you can ask us to rectify
          inaccurate personal data and to complete incomplete personal data;
          <br /> (c) the right to erasure – you can ask us to erase your
          personal data;
          <br /> (d) the right to restrict processing – you can ask us to
          restrict the processing of your personal data;
          <br /> (e) the right to object to processing – you can object to the
          processing of your personal data;
          <br /> (f) the right to data portability – you can ask that we
          transfer your personal data to another organisation or to you;
          <br /> (g) the right to complain to a supervisory authority – you can
          complain about our processing of your personal data;
          <br /> and (h) the right to withdraw consent – to the extent that the
          legal basis of our processing of your personal data is consent, you
          can withdraw that consent.
        </Text>
        <Text mb={3}>
          8.3 These rights are subject to certain limitations and exceptions.
          You can learn more about the rights of data subjects by visiting{' '}
          <Link to="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">
            https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/
          </Link>
          .
        </Text>
        <Text mb={4}>
          8.4 You may exercise any of your rights in relation to your personal
          data by written notice to us, using the contact details set out below.
        </Text>

        <Text variant="h2" mb={3}>
          Section 9: About cookies
        </Text>
        <Text mb={3}>
          9.1 A cookie is a file containing an identifier (a string of letters
          and numbers) that is sent by a web server to a web browser and is
          stored by the browser. The identifier is then sent back to the server
          each time the browser requests a page from the server.
        </Text>
        <Text mb={3}>
          9.2 Cookies may be either “persistent” cookies or “session” cookies: a
          persistent cookie will be stored by a web browser and will remain
          valid until its set expiry date, unless deleted by the user before the
          expiry date; a session cookie, on the other hand, will expire at the
          end of the user session, when the web browser is closed.
        </Text>
        <Text mb={4}>
          9.3 Cookies may not contain any information that personally identifies
          a user, but personal data that we store about you may be linked to the
          information stored in and obtained from cookies.
        </Text>

        <Text variant="h2" mb={3}>
          Section 10: Cookies that we use
        </Text>
        <Text mb={4}>
          10.1 We use cookies for the following purposes:
          <br /> (a) authentication and status – we use cookies to identify you
          when you visit our website and as you navigate our website, and to
          help us determine if you are logged into our website;
          <br /> (b) shopping cart – we use cookies to maintain the state of
          your shopping cart as you navigate our website;
          <br />
          (c) personalization – we use cookies to store information about your
          preferences and to personalise our website for you;
          <br /> (d) security – we use cookies as an element of the security
          measures used to protect user accounts, including preventing
          fraudulent use of login credentials, and to protect our website and
          services generally;
          <br /> (e) advertising – we use cookies to help us to display
          advertisements that will be relevant to you;
          <br /> (f) analysis – we use cookies to help us to analyse the use and
          performance of our website and services;
          <br /> and (g) cookie consent – we use cookies to store your
          preferences in relation to the use of cookies more generally.
        </Text>

        <Text variant="h2" mb={3}>
          Section 11: Cookies used by our service providers
        </Text>
        <Text mb={3}>
          11.1 Our service providers use cookies and those cookies may be stored
          on your computer when you visit our website.
        </Text>
        <Text mb={4}>
          11.2 We use Google Analytics. Google Analytics gathers information
          about the use of our website by means of cookies. The information
          gathered is used to create reports about the use of our website. You
          can find out more about Google’s use of information by visiting{' '}
          <Link to="https://www.google.com/policies/privacy/partners/">
            https://www.google.com/policies/privacy/partners/
          </Link>{' '}
          and you can review Google’s privacy policy at{' '}
          <Link to="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </Link>
          .
        </Text>

        <Text variant="h2" mb={3}>
          Section 12: Managing cookies
        </Text>
        <Text mb={3}>
          12.1 Most browsers allow you to refuse to accept cookies and to delete
          cookies. The methods for doing so vary from browser to browser, and
          from version to version. You can however obtain up-to-date information
          about blocking and deleting cookies via these links:
          <br /> (a) https://support.google.com/chrome/answer/95647 (Chrome);
          <br /> (b)
          https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
          (Firefox);
          <br /> (c) https://help.opera.com/en/latest/security-and-privacy/
          (Opera);
          <br /> (d)
          https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
          (Internet Explorer);
          <br /> (e)
          https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
          (Safari);
          <br /> and (f)
          https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
          (Edge).
        </Text>
        <Text mb={3}>
          12.2 Blocking all cookies will have a negative impact upon the
          usability of many websites.
        </Text>
        <Text mb={4}>
          12.3 If you block cookies, you will not be able to use all the
          features on our website.
        </Text>

        <Text variant="h2" mb={3}>
          Section 13: Amendments
        </Text>
        <Text mb={3}>
          13.1 We may update this policy from time to time by publishing a new
          version on our website.
        </Text>
        <Text mb={3}>
          13.2 You should check this page occasionally to ensure you are happy
          with any changes to this policy.
        </Text>
        <Text mb={4}>
          13.3 We may notify you of changes to this policy by email.
        </Text>

        <Text variant="h2" mb={3}>
          Section 14: Our details
        </Text>
        <Text mb={3}>
          14.1 This website is owned and operated by Spring Rain Private Ltd.
        </Text>
        <Text mb={3}>
          14.2 Our principal place of business is at: 7th Floor, Commercial
          Cove, House 56/C Rd 132, Dhaka 1212.
        </Text>
        <Text mb={4}>
          14.3 You can contact us:
          <br /> (a) by post, to the postal address given above;
          <br /> (b) using our website contact form;
          <br /> (c) by telephone, on the contact number published on our
          website;
          <br /> or (d) by email, using [the email address published on our
          website.
        </Text>

        <Text variant="h2" mb={3}>
          Section 15: Data protection officer
        </Text>
        <Text mb={3}>
          15.1 Contact details of our data protection officer is:
          sapp.dev@springrain.io.
        </Text>
      </Box>
    </MainLayout>
  );
};

export default PrivacyPolicy;
